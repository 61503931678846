

import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"

import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}

export const onInitialClientRender = () => {
  setTimeout(function() {
    document.getElementById("___loader").style.display = "none"
    document.getElementById("___gatsby").classList.remove("blurred");
  }, 2000);
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if(prevLocation){
      document.location.reload();
  } 
}
